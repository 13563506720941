<script setup lang="ts">
import CloseIcon from '~/assets/icons/digital/close.svg'

withDefaults(defineProps<{ show: boolean, title: string, width?: string, isLoading?: boolean }>(), { width: '900px' })
const emit = defineEmits<{ (e: 'close'): void }>()
</script>

<template>
  <n-modal
    :mask-closable="false"
    :show="show"
    :style="`width: ${width}`"
    @esc="emit('close')"
    @close="emit('close')"
  >
    <TheDataCard :title="title" :is-loading="isLoading" header-class="bg-gray-200 flex-nowrap md:flex-wrap">
      <template #header-extra>
        <n-button quaternary circle @click="$emit('close')">
          <div class="h-6 w-6">
            <CloseIcon />
          </div>
        </n-button>
      </template>
      <slot />
    </TheDataCard>
  </n-modal>
</template>
